import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import './assets/css/styles.css';

import ServicePage from "./pages/ServicePage";
import AboutPage from "./pages/AboutPage";
import ProductPage from "./pages/ProductPage";
import ContactPage from "./pages/ContactPage";
import JobPage from "./pages/JobPage";
import JobApply from "./pages/JobApply";

import NotFoundPage from "./pages/NotFoundPage";
import InternshipPage from "pages/InternshipPage";
import InternshipApplyPage from "pages/InternshipApplyPage";
import SolutionsPage from "pages/SolutionsPage";
import ProductDetailPage from "pages/ProductDetailPage";
import { Analytics } from "@vercel/analytics/react"
import ServiceDetailPage from "pages/ServiceDetailPage";
import { ServicesProvider } from "context/ServiceContext";
import { ActiveProjectProvider } from "context/ActiveProjectContext";
import { ProductsProvider } from "context/ProductContext";
import { InternshipsProvider } from "context/InternshipContext";
import { JobsProvider } from "context/JobContext";
import BlogPage from "pages/BlogPage";
import BlogDetailPage from "pages/BlogDetailPage";
import { BlogsProvider } from "context/BlogContext";
import { BannersProvider } from "context/BannerContext";


function App() {
  return (
  <BannersProvider>
   <BlogsProvider>
    <JobsProvider>
      <InternshipsProvider>
        <ProductsProvider>
          <ServicesProvider>
          <ActiveProjectProvider> 
              <Routes>
                <Route exact path="/" element={<LandingPage />}  />
                <Route path="/services" element={<ServicePage />} />
                <Route path="/services/:serviceId" element={<ServiceDetailPage />} /> 
                <Route path="/about" element={<AboutPage />} />
                <Route path="/product" element={<ProductPage />} />
                <Route path="/product/:productId" element={<ProductDetailPage />} /> 
                <Route path="/discuss-project" element={<ContactPage />} />
                <Route path="/jobs" element={<JobPage />} />
                <Route path="/apply/:jobId" element={<JobApply />} /> 
                <Route path="/internship" element={<InternshipPage />} />
                <Route path="/internship-apply/:jobId" element={<InternshipApplyPage />} /> 
                <Route path="/solutions" element={<SolutionsPage />}/>
                <Route path="/blogs" element={<BlogPage />} />
                <Route path="/blog/:blogId" element={<BlogDetailPage />} /> 

                <Route path="*" element={<NotFoundPage />} /> {/* Updated path to "*" */}
              </Routes>
              <Analytics />
            </ActiveProjectProvider> 
          </ServicesProvider>
        </ProductsProvider>
      </InternshipsProvider>
    </JobsProvider>
  </BlogsProvider> 
</BannersProvider> 
   
  );
}

export default App;
