
import React, { useEffect, useContext, useState } from "react";

import Header from '../parts/Header';
import Hero from "parts/Hero";
import MessageBanner from "parts/MessageBanner";
import ProductIcon from "parts/ProductIcon";
import Service from "parts/Service";
import {
  Services,
  CurrentProjects,
  Products as staticProducts  // Static products for fallback
} from "json/landingPageData";
import Footer from "parts/Footer";
import CurrentProject from "parts/CurrentProject";
import InfoTooltip from "parts/InfoTooltip";
import { ServicesContext } from "context/ServiceContext";
import { ActiveProjectContext } from "context/ActiveProjectContext";
import Load from "parts/Load";
import ChatBot from "parts/ChatBot";

const LandingPage = () => {
  const { services, loading: servicesLoading, error: servicesError } = useContext(ServicesContext);
  const { projects, loading: projectsLoading, error: projectsError } = useContext(ActiveProjectContext);

  const [servicesData, setServicesData] = useState([]); // State to hold the services data
  const [productsData, setProductsData] = useState([]); // State to hold the products data

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Effect to handle services data
  useEffect(() => {
    if (servicesLoading) return;
    if (servicesError) {
      setServicesData(Services); // Use static services data on error
    } else {
      setServicesData(services?.data || Services); // Use API data when available, fallback to static
    }
  }, [servicesLoading, servicesError, services]);

  // Effect to handle products data
  useEffect(() => {
    if (projectsLoading) return;
    if (projectsError) {
      setProductsData(staticProducts); // Use static products data on error
    } else {
      setProductsData(projects?.data || staticProducts); // Use API data when available, fallback to static
    }
  }, [projectsLoading, projectsError, projects]);

  // If still loading, show loading message
  if (servicesLoading || projectsLoading) return <Load />;

  return (
    <>    
      <Header />
      <MessageBanner/>
      <Hero />
      <ChatBot />
      <InfoTooltip />
      <ProductIcon />
      <Service data={servicesData} />
      <CurrentProject data={productsData} />
      <Footer />
    </>
  );
}

export default LandingPage;




// import React, { Component } from "react";
// import Header from '../parts/Header'
// import Hero from "parts/Hero";
// import ProductIcon from "parts/ProductIcon";
// import Service from "parts/Service";
// import {
//   Services,
//   CurrentProjects,
//   Products
// } from "json/landingPageData";
// import Footer from "parts/Footer";
// import CurrentProject from "parts/CurrentProject";
// import InfoTooltip from "parts/InfoTooltip"; 

// export default class LandingPage extends Component {
//   componentDidMount() {
//     window.scrollTo(0, 0);
//   }

//   render() {
//     return (
//       <>
//         <Header />
//         <Hero />
//         <InfoTooltip />
//         <ProductIcon />
//         <Service data={Services} />
//         <CurrentProject data={Products} />
//         <Footer />
        
//       </>
//     );
//   }
// }
