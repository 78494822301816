import React, { useContext } from 'react';
import { Fade } from 'react-awesome-reveal';
import { BannerContext } from '../context/BannerContext'; // Adjust the path to match your file structure

export default function MessageBanner() {
  const { banners, loading, error } = useContext(BannerContext);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  // Assuming only the first banner is displayed
  const bannerImage = banners.length > 0 ? banners[0].image : null;
  console.log("bannerImage:", bannerImage);

  return (
    bannerImage ? (
      <Fade direction="up" triggerOnce>
        <section className="flex container mx-auto justify-center">
          <div className="p-2 sm:w-full lg:w-11/12 max-w-[350px] sm:max-w-full">
            <img
              src={bannerImage}
              alt={banners[0]?.title || 'Banner'}
              className="rounded-lg w-full h-auto max-h-[200px]"
            />
          </div>
        </section>
      </Fade>
    ) : null
  );
}
