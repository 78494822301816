
import { createContext, useEffect, useState } from "react";
import api from "../api/api";

// Create the context
export  const  BannerContext = createContext();


// Create the context provider component
export const BannersProvider = ({children}) => {
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //Function to fetch all banners from the API
    const fetchBanner = async () => {
        try {
          const response = await api.banners.fetchBanner();
          if (response?.data) {
            setBanners(response.data); // Use the `data` field from the API response
          } else {
            setBanners([]); // Set to an empty array if no `data` field is present
          }
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };
      

    // Fetch banners when the component mounts
    useEffect(() => {
        fetchBanner();
    },[]);

    return(
        <BannerContext.Provider value={{banners,loading, error}} >
            {children}
        </BannerContext.Provider>
    )

}



